import {
  Box,
  BoxProps,
  Collapse,
  Flex,
  Icon,
  Image,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/core"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { NumberParam, StringParam, useQueryParam } from "use-query-params"

import BlogArticleList from "../components/BlogArticleList"
import ButtonGroup from "../components/ButtonGroup"
import { ConnectedTextField as TextField } from "../components/FormFields/TextField"
import MaxWidthGrid from "../components/Layout/MaxWidthGrid"
import PageContainer from "../components/PageContainer"
import SEO from "../components/boilerplate/seo"
import Heading from "../components/typography/Heading"

import { TransformedBlogListQuery } from "../templates/blogList"
import { toSlug } from "../utils"
import { bp } from "../utils/MediaQueries"

interface BlogListPageProps {
  data: TransformedBlogListQuery
  location: Location
}

const Divider: React.FC<BoxProps> = (props) => (
  <Box h="1.2px" w="9.75rem" mx="auto" backgroundColor="#ccc" {...props} />
)

const PAGE_SIZE = 9

const BlogListPage: React.FC<BlogListPageProps> = ({
  data: { blogs, globalConfig },
  location,
}) => {
  const [search, setSearch] = useQueryParam("search", StringParam)
  const [searchKeyword, setSearchKeyword] = React.useState("")
  const [category, setCategory] = useQueryParam("category", StringParam)
  const [page = 1, setPage] = useQueryParam("page", NumberParam)
  const [showSearch, setShowSearch] = React.useState(!!search)
  const [showTags, setShowTags] = React.useState(false)
  const formRefDesktop = React.useRef<HTMLFormElement>()
  const formRefMobile = React.useRef<HTMLFormElement>()

  const methods = useForm()

  const allArticles = blogs.edges
  var categories = React.useMemo(() => {
    return Array.from(
      allArticles.reduce((acc, next) => {
        // @ts-ignore
        next.node.tags?.forEach((tag) => acc.add(tag.toLowerCase()))
        return acc
      }, new Set())
    )
  }, [allArticles]).map((category: string) => ({
    label: category,
    value: category,
  }))

  const allFilteredArticles = allArticles.filter((article) => {
    if (category && !article.node.sluggedTags?.includes(category)) {
      return false
    }

    if (
      showSearch &&
      search &&
      !article.node.title?.toLowerCase()?.match(search.toLowerCase())?.length
    ) {
      return false
    }

    return true
  })

  const filteredFeatured = allFilteredArticles.filter((article) => {
    if (!article.node.sluggedTags?.includes("featured")) {
      return false
    }
    return true
  })
  const filteredHowTos = allFilteredArticles.filter((article) => {
    if (!article.node.sluggedTags?.includes("howto")) {
      return false
    }
    if (!article.node.title?.toLowerCase()?.match("how to")?.length) {
      return false
    }
    return true
  })
  const restArticles = allFilteredArticles.filter((article) => {
    if (
      article.node.sluggedTags?.includes("featured") ||
      article.node.sluggedTags?.includes("howto") ||
      article.node.title?.toLowerCase()?.match("how to")
    ) {
      return false
    }
    return true
  })

  // const restArticles = allArticles

  // console.log({ allFilteredArticles })
  // console.log({ filteredHowTos })
  // console.log({ allArticles })
  // console.log({ restArticles })

  const toggleSearch = () => {
    setSearch(showSearch ? undefined : methods.getValues("search"))
    setShowSearch(!showSearch)
    if (!showSearch) methods.reset()
  }

  const toggleTagShow = () => {
    if (showTags) {
      setShowTags(false)
    } else {
      setShowTags(true)
    }
  }

  const handleSearchSubmitDesktop = ({ search }: { search: string }) => {
    setSearch(search)
  }
  const handleSearchSubmitMobile = ({ search }: { search: string }) => {
    setSearch(search)
  }

  const handleFilterChange = (value) => {
    setPage(1)
    setCategory(value ? toSlug(value) : undefined)
  }

  // Trim all values from associative array.
  for (var i = 0; i < categories.length; i++) {
    categories[i].label = categories[i].label.replace(/\s+/g, " ").trim()
    categories[i].value = categories[i].value.replace(/\s+/g, " ").trim()
  }
  // Remove all duplicates
  categories = Array.from(
    categories.reduce((a, o) => a.set(o.value, o), new Map()).values()
  )

  return (
    <PageContainer>
      <SEO
        title="Bike Advice | Best Bike Blog For Beginners | Beach Cruiser & Cycling Blogs"
        location={location}
      />
      <MaxWidthGrid>
        <Box gridColumn={bp("1/end", "2/14")}>
          <Flex
            mb="20px"
            mt="20px"
            justifyContent={"space-between"}
            alignContent={"center"}
            alignItems={"center"}
            w={"100%"}
          >
            <Flex
              justifyContent={"flex-start"}
              alignContent={"center"}
              alignItems={"center"}
              w={bp("auto", "48%")}
            >
              <Image
                src="/images/logo.png"
                alt="SixThreeZero"
                width={bp("40px", "45px")}
                fallbackSrc="/images/emptyImg.png"
              />
              <Heading fontSize={bp("1.9rem", "2rem")} fontWeight="bold">
                Bike Advice
              </Heading>
            </Flex>
            <Flex
              w={bp("auto", "48%")}
              justifyContent={"flex-end"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Box d={bp("none", "block")} w="100%" maxW="400px">
                <Collapse isOpen={true} mb="20px">
                  <FormProvider {...methods}>
                    <Box
                      as="form"
                      ref={formRefDesktop}
                      onSubmit={methods.handleSubmit(handleSearchSubmitDesktop)}
                    >
                      <Stack spacing={4}>
                        <InputGroup>
                          <TextField
                            w="100%"
                            height="2.625rem"
                            name="search"
                            placeholder="Search Bike Advice"
                            defaultValue={searchKeyword}
                            onChange={(e) =>
                              setSearchKeyword(e.currentTarget.value)
                            }
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const event = new Event("submit", {
                                  bubbles: true,
                                  cancelable: true,
                                })
                                formRefDesktop?.current?.dispatchEvent(event)
                              }
                            }}
                          />
                          <InputRightElement>
                            <Icon name="search-2" color="#c3c8ccb8" />
                          </InputRightElement>
                        </InputGroup>
                      </Stack>

                      <Box
                        as="input"
                        // @ts-ignore
                        type="submit"
                        display="none"
                      />
                    </Box>
                  </FormProvider>
                </Collapse>
              </Box>

              <Icon
                onClick={toggleSearch}
                d={bp("block", "none")}
                name="search-2"
                size="20px"
                mr="20px"
                color={showSearch ? "#005f78" : "night"}
              />

              <Icon
                onClick={toggleTagShow}
                d={bp("block", "none")}
                name="drawer"
                size="25px"
                color={showTags ? "#005f78" : "night"}
              />
            </Flex>
          </Flex>

          <Collapse isOpen={showSearch} mb="20px" d={bp("block", "none")}>
            <FormProvider {...methods}>
              <Box
                as="form"
                ref={formRefMobile}
                onSubmit={methods.handleSubmit(handleSearchSubmitMobile)}
              >
                <TextField
                  w="100%"
                  height="2.625rem"
                  name="search"
                  placeholder="Search Bike Advice"
                  defaultValue={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.currentTarget.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      const event = new Event("submit", {
                        bubbles: true,
                        cancelable: true,
                      })
                      formRefMobile?.current?.dispatchEvent(event)
                    }
                  }}
                />
                <Box
                  as="input"
                  // @ts-ignore
                  type="submit"
                  display="none"
                />
              </Box>
            </FormProvider>
          </Collapse>

          <Collapse isOpen={true} d={bp("none", "block")}>
            <ButtonGroup options={categories} onChange={handleFilterChange} />
          </Collapse>

          <Collapse isOpen={showTags} d={bp("block", "none")}>
            <ButtonGroup options={categories} onChange={handleFilterChange} />
          </Collapse>

          <Divider
            mt="1rem"
            mb="1rem"
            w={"100%"}
            backgroundColor="#000000"
            style={{ opacity: "25%" }}
          />

          <BlogArticleList
            blogLayoutNew={true}
            // @ts-ignore
            articles={allFilteredArticles.map((article) => article.node)}
            filteredFeatured={filteredFeatured.map((article) => article.node)}
            filteredHowTos={filteredHowTos.map((article) => article.node)}
            restArticles={restArticles.map((article) => article.node)}
            blogCategories={globalConfig?.blogPageCategories}
            emptyText={`No articles available`}
            pageSize={PAGE_SIZE}
            mt={bp("1rem", "2rem")}
          />
        </Box>
      </MaxWidthGrid>
    </PageContainer>
  )
}

export default BlogListPage
