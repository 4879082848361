import { Flex, Link } from "@chakra-ui/core"
import Body from "../../components/typography/Body"
import React from "react"
import { bp } from "../../utils/MediaQueries"

function BlogCategoryCards({ blogCategories }) {
  return (
    <Flex
      mt={bp("1.3rem", "2rem")}
      mb={bp("1.3rem", "3.7rem")}
      style={{ gap: "10px" }}
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      flexWrap="nowrap"
      overflow="auto"
    >
      {blogCategories.slice(0, 7).map((cat, index) => (
        <Link
          href={`/collections/${cat?.internalTitle}`}
          w={bp("100%", "100%")}
          textDecoration="none"
          _hover={{ textDecoration: "none" }}
          key={index}
        >
          <Flex
            w={bp("95px", "100%")}
            h={bp("160px", "250px")}
            backgroundColor="#ccc"
            borderRadius="5px"
            background={
              cat?.blogCategoryImage?.fluid?.src
                ? `url(${cat?.blogCategoryImage?.fluid?.src})`
                : null
            }
            backgroundPosition="center center"
            backgroundSize="cover"
            flexDirection="column"
            justifyContent="flex-end"
            alignContent="center"
            alignItems="center"
            p={bp("7px", "15px")}
          >
            <Body
              as="h4"
              textAlign="center"
              fontWeight="800"
              fontSize={bp("0.9rem", "1.1rem")}
              color="#fff"
              textDecoration="none"
            >
              {cat?.title}
            </Body>
          </Flex>
        </Link>
      ))}
    </Flex>
  )
}

export default BlogCategoryCards
