import { BoxProps, Flex, Image } from "@chakra-ui/core"
import { Link } from "gatsby"
import { FluidObject } from "gatsby-image"
import React from "react"
import { bp } from "../../utils/MediaQueries"
import Body from "../typography/Body"

type ArticleBlockItemProps = BoxProps & {
  image: FluidObject
  title: string
  subtext?: React.ReactNode
  tags?: any
  to: string
}

export default function FeaturedBlogItem({
  image,
  title,
  subtext,
  tags,
  to,
  ...props
}: ArticleBlockItemProps) {
  return (
    <Flex
      flexBasis="100%"
      borderRadius="5px"
      maxW={bp("100%", "32%")}
      h={bp("150px", "auto")}
    >
      <Link to={to} style={{ width: "100%" }}>
        <Flex
          flexDirection={bp("row", "column")}
          h={bp("100%", "100%")}
          width="100%"
          textAlign="left"
          background="#eee"
          borderRadius="5px"
          {...props}
        >
          <Image
            src={image?.src}
            fallbackSrc="/images/emptyImg.png"
            alt="SixThreeZero"
            w={bp("40%", "100%")}
            h={bp("auto", "250px")}
            borderTopLeftRadius={bp("5px", "5px")}
            borderTopRightRadius={bp("0px", "5px")}
            borderBottomLeftRadius={bp("5px", "0px")}
            borderBottomRightRadius={bp("0px", "0px")}
            objectFit="cover"
            style={{ aspectRatio: "1/1" }}
          />
          <Flex flexDirection="column" p="0px 20px 20px 20px">
            <Body
              fontSize={bp("0.8rem", "0.9rem")}
              // color="#999"
              fontWeight="bold"
              textTransform="uppercase"
              mt={bp("1rem", "1rem")}
            >
              {tags?.map((tag) => `${tag}`).join(", ")}
            </Body>
            <Body
              size="md"
              color="night"
              fontWeight="bold"
              mt={bp("0.5rem", "0.5rem")}
            >
              {title}
            </Body>
            {/* {subtext && (
          <Body size="md" color="dawn" mt={bp("0.4825rem", "0.5rem")}>
            {subtext}
          </Body>
        )} */}
          </Flex>
        </Flex>
      </Link>
    </Flex>
  )
}
