import React from "react"
import { compose } from "recompose"
import { graphql } from "gatsby"
import { BlogListQuery } from "../../graphql-types"
import { toSlug } from "../utils"
import BlogListPage from "../views/BlogListPage"

export const query = graphql`
  query BlogList($slugs: [String]!) {
    globalConfig: contentfulGlobalConfiguration {
      blogPageCategories {
        title
        seoTitle
        internalTitle
        blogCategoryImage {
          fluid {
            src
          }
        }
      }
    }
    blogs: allContentfulBlogPost(
      sort: { order: DESC, fields: createdOn }
      filter: { slug: { in: $slugs } }
    ) {
      edges {
        node {
          slug
          title
          createdOn
          tags
          excerpt {
            raw
          }
          author {
            name
          }
          postImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export type TransformedBlogListQuery = ReturnType<typeof transformData>

const transformData = (data: BlogListQuery) => {
  const transformedBlogs = data.blogs.edges.map((blog) => {
    return {
      ...blog,
      node: {
        ...blog.node,
        sluggedTags: blog.node.tags?.map((tag) => tag && toSlug(tag)),
      },
    }
  })

  return {
    ...data,
    blogs: {
      edges: transformedBlogs,
    },
  }
}

function withTransformData(WrappedComponent) {
  const Wrapped = (props) => (
    <WrappedComponent {...props} data={transformData(props.data)} />
  )
  return Wrapped
}

export default compose(withTransformData)(BlogListPage)
