import React from "react"
import { Flex, FlexProps } from "@chakra-ui/core"
import { Button } from "../Buttons"

type ButtonGroupProps = Omit<FlexProps, "defaultValue"> & {
  defaultValue?: string | null
  options: {
    label: string
    value: string
  }[]
  onChange?: (value: string | null) => void
}

export default function ButtonGroup({
  defaultValue = null,
  options,
  onChange,
  children,
  ...props
}: ButtonGroupProps) {
  const [value, setValue] = React.useState<string | null>(defaultValue)

  return (
    <Flex flexWrap="wrap" {...props}>
      {options.slice(0, 7).map((option) => (
        <Button
          key={option.value}
          theme={value === option.value ? "toggled" : "tertiary"}
          px="0.2rem"
          mr="1rem"
          mb="0rem"
          h="2rem"
          style={{ border: "none" }}
          onClick={() => {
            const newValue = option.value === value ? null : option.value
            setValue(newValue)
            onChange?.(newValue)
          }}
        >
          {option.label}
        </Button>
      ))}
      {children}
    </Flex>
  )
}
