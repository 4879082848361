import { Box, BoxProps, Flex, Image } from "@chakra-ui/core"
import truncate from "lodash/truncate"
import React from "react"
import { NumberParam, useQueryParam } from "use-query-params"

import { Link } from "gatsby"
import { FluidObject } from "gatsby-image"
import {
  ContentfulAsset,
  ContentfulBlogPost,
  Maybe,
} from "../../../graphql-types"
import ArrowDownIcon from "../../components/Quiz/ArrowDownIcon"
import { placeholderImage } from "../../utils"
import { bp } from "../../utils/MediaQueries"
import { rawRichTextToString } from "../../utils/richText"
import ArticleBlock from "../ArticleBlock"
import FeaturedBlogItem from "../ArticleBlockItem/FeaturedBlogItem"
import HowToBlogItem from "../ArticleBlockItem/HowToBlogItem"
import BlogCategoryCards from "../BlogList/BlogCategoryCards"
import Body from "../typography/Body"

type ArticleListProps = BoxProps & {
  articles: (Pick<
    ContentfulBlogPost,
    "excerpt" | "slug" | "title" | "tags" | "author" | "createdOn"
  > & {
    postImage?: Maybe<Pick<ContentfulAsset, "fluid">>
  })[]
  filteredFeatured?: any
  filteredHowTos?: any
  restArticles?: any
  pageSize?: number
  emptyText?: string
  blogLayoutNew?: boolean
  blogCategories?: any
  author?: any
  createdOn?: any
}

const Divider: React.FC<BoxProps> = (props) => (
  <Box h="1.2px" w="9.75rem" mx="auto" backgroundColor="#ccc" {...props} />
)

export default function BlogArticleList({
  articles: allArticles,
  filteredHowTos: filteredHowTos,
  filteredFeatured: filteredFeatured,
  restArticles: restArticles,
  blogCategories: blogCategories,
  pageSize = 9,
  emptyText = "No articles available",
  blogLayoutNew = false,
  ...props
}: ArticleListProps) {
  const [page = 1, setPage] = useQueryParam("page", NumberParam)
  const baseZeroPage = page! - 1

  const [latestArticle, ...articles] = allArticles
  const pageCount = Math.ceil(articles.length / pageSize)

  const handleNext = () => {
    setPage(Math.min(page! + 1, pageCount))
  }
  const handlePrev = () => {
    setPage(Math.max(page! - 1, 1))
  }

  return (
    <Box {...props}>
      {!latestArticle && (
        <Body size="lg" color="dawn" mt="1rem" textAlign="center">
          {emptyText}
        </Body>
      )}
      {latestArticle &&
        latestArticle?.title &&
        (!blogLayoutNew ? (
          <ArticleBlock
            tag="Latest"
            title={latestArticle.title!}
            subtext={truncate(
              rawRichTextToString(latestArticle?.excerpt?.raw),
              {
                length: 86,
              }
            )}
            to={`/blogs/bike-advice/${latestArticle.slug}`}
            image={
              (latestArticle.postImage?.fluid ||
                placeholderImage) as FluidObject
            }
            mb="7.5rem"
          />
        ) : (
          <Link
            to={`/blogs/bike-advice/${latestArticle.slug}`}
            style={{ width: "100%" }}
          >
            <Flex
              flexDirection={bp("column", "row")}
              justifyContent="space-between"
              alignItems="stretch"
              alignContent="center"
            >
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                alignContent="flex-start"
                background="#eee"
                width={bp(
                  "100%",
                  latestArticle?.postImage?.fluid?.srcWebp ? "50%" : "100%"
                )}
                height={bp("auto", "auto")}
                padding={bp("16px", "30px")}
                order={bp(2, 1)}
              >
                <Body
                  as="h2"
                  fontSize={bp("13px", "18px")}
                  fontWeight="800"
                  textTransform="uppercase"
                >
                  Latest
                </Body>
                <Body
                  as="h2"
                  fontSize={bp("30px", "48px")}
                  fontWeight="bold"
                  // maxW="75%"
                  lineHeight={bp("35px", "52px")}
                  mt="10px"
                  mb={bp("10px", "20px")}
                >
                  {latestArticle?.title?.substring(0, 35)}..
                </Body>
                <Body as="p">
                  {truncate(rawRichTextToString(latestArticle?.excerpt?.raw), {
                    length: 86,
                  })}
                </Body>
                <Flex
                  width="100%"
                  justifyContent="flex-start"
                  alignItems="center"
                  mt={bp("20px", "30px")}
                >
                  <Body
                    as="h4"
                    fontSize={bp("0.8rem", "1rem")}
                    fontWeight={800}
                  >
                    READ MORE
                  </Body>
                  <ArrowDownIcon
                    style={{ transform: "rotate(-45deg)" }}
                    className="text-down-arrow"
                    w="20px"
                    marginLeft="15px"
                  />
                </Flex>
              </Flex>

              {latestArticle?.postImage?.fluid?.srcWebp && (
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="flex-start"
                  width={bp("100%", "50%")}
                  height={bp("auto", "400px")}
                  order={bp(1, 2)}
                >
                  {latestArticle?.postImage?.fluid?.srcWebp && (
                    <Image
                      src={`${latestArticle?.postImage?.fluid?.srcWebp}`}
                      fallbackSrc="/images/emptyImg.png"
                      alt="Latest Image"
                      height={bp("230px", "100%")}
                      width="100%"
                      objectFit="cover"
                    />
                  )}
                </Flex>
              )}
            </Flex>
          </Link>
        ))}

      <BlogCategoryCards blogCategories={blogCategories} />

      {!latestArticle && (
        <Body size="lg" color="dawn" mt="1rem" textAlign="center">
          {emptyText}
        </Body>
      )}

      {filteredFeatured &&
        filteredFeatured
          ?.slice(baseZeroPage * pageSize, baseZeroPage * pageSize + pageSize)
          ?.slice(0, 3)?.length > 0 && (
          <>
            <Flex w="100%">
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="nowrap"
                alignContent="center"
                w="100%"
              >
                <Body
                  as="h3"
                  fontSize={bp("24px", "34px")}
                  textTransform="uppercase"
                  fontWeight={"bold"}
                  minW="fit-content"
                  paddingRight="20px"
                >
                  Featured
                </Body>
                <Divider
                  mt="0"
                  mb="0"
                  w={"100%"}
                  backgroundColor="#000000"
                  style={{ opacity: "25%" }}
                />
              </Flex>
            </Flex>

            {filteredFeatured &&
            filteredFeatured
              ?.slice(
                baseZeroPage * pageSize,
                baseZeroPage * pageSize + pageSize
              )
              ?.slice(0, 3)?.length > 0 ? (
              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                style={{ gap: "20px" }}
                mt={bp("1rem", "2rem")}
                mb={bp("3rem", "4.5rem")}
              >
                {filteredFeatured
                  ?.slice(
                    baseZeroPage * pageSize,
                    baseZeroPage * pageSize + pageSize
                  )
                  ?.slice(0, 3)
                  ?.map((article) => (
                    <FeaturedBlogItem
                      key={article?.slug}
                      title={article?.title!}
                      subtext={truncate(
                        rawRichTextToString(article.excerpt?.raw),
                        {
                          length: 79,
                        }
                      )}
                      tags={article?.tags}
                      to={`/blogs/bike-advice/${article.slug}`}
                      image={
                        (article.postImage?.fluid ||
                          placeholderImage) as FluidObject
                      }
                    />
                  ))}
              </Flex>
            ) : (
              <Body
                size="lg"
                color="dawn"
                mt="1rem"
                mb="2rem"
                textAlign="right"
              >
                {emptyText}
              </Body>
            )}
          </>
        )}

      {filteredHowTos &&
        filteredHowTos
          ?.slice(baseZeroPage * pageSize, baseZeroPage * pageSize + pageSize)
          ?.slice(0, 3)?.length > 0 && (
          <>
            <Flex w="100%">
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="nowrap"
                alignContent="center"
                w="100%"
              >
                <Body
                  as="h3"
                  fontSize={bp("24px", "34px")}
                  textTransform="uppercase"
                  fontWeight={"bold"}
                  minW="fit-content"
                  paddingRight="20px"
                >
                  How To
                </Body>
                <Divider
                  mt="0"
                  mb="0"
                  w={"100%"}
                  backgroundColor="#000000"
                  style={{ opacity: "25%" }}
                />
              </Flex>
            </Flex>

            {filteredHowTos &&
              filteredHowTos
                ?.slice(
                  baseZeroPage * pageSize,
                  baseZeroPage * pageSize + pageSize
                )
                ?.slice(0, 3)?.length > 0 && (
                <Flex
                  flexDirection={bp("column", "column")}
                  border="1px sold red"
                  style={{ gap: "20px" }}
                  mt={bp("1rem", "2rem")}
                  mb={bp("3rem", "4.5rem")}
                >
                  {filteredHowTos
                    ?.slice(
                      baseZeroPage * pageSize,
                      baseZeroPage * pageSize + pageSize
                    )
                    ?.slice(0, 3)
                    ?.map((article) => (
                      <HowToBlogItem
                        key={article.slug}
                        title={article.title!}
                        subtext={truncate(
                          rawRichTextToString(article.excerpt?.raw),
                          {
                            length: 79,
                          }
                        )}
                        author={article?.author}
                        createdOn={article?.createdOn}
                        tags={article?.tags}
                        to={`/blogs/bike-advice/${article.slug}`}
                        image={
                          (article.postImage?.fluid ||
                            placeholderImage) as FluidObject
                        }
                      />
                    ))}
                </Flex>
              )}
          </>
        )}

      {restArticles && restArticles?.length > 0 && (
        <>
          <Flex w="100%">
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
              alignContent="center"
              w="100%"
            >
              <Body
                as="h3"
                fontSize={bp("24px", "34px")}
                textTransform="uppercase"
                fontWeight={"bold"}
                minW="fit-content"
                paddingRight="20px"
              >
                Related
              </Body>
              <Divider
                mt="0"
                mb="0"
                w={"100%"}
                backgroundColor="#000000"
                style={{ opacity: "25%" }}
              />
            </Flex>
          </Flex>

          {restArticles && restArticles?.length > 0 ? (
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              style={{ gap: "20px" }}
              mt={bp("1rem", "2rem")}
              mb={bp("3rem", "4.5rem")}
            >
              {restArticles
                .slice(
                  baseZeroPage * pageSize,
                  baseZeroPage * pageSize + pageSize
                )
                ?.map((article) => (
                  <FeaturedBlogItem
                    key={article?.slug}
                    title={article?.title!}
                    subtext={truncate(
                      rawRichTextToString(article.excerpt?.raw),
                      {
                        length: 79,
                      }
                    )}
                    tags={article?.tags}
                    to={`/blogs/bike-advice/${article.slug}`}
                    image={
                      (article.postImage?.fluid ||
                        placeholderImage) as FluidObject
                    }
                  />
                ))}
            </Flex>
          ) : (
            <Body size="lg" color="dawn" mt="1rem" mb="2rem" textAlign="right">
              {emptyText}
            </Body>
          )}
        </>
      )}
    </Box>
  )
}
